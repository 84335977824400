import React, {useEffect} from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
// import { graphql } from "gatsby";
import { Routes } from '@components/routes';
import ArticleText from '@components/articleTextSidebar';
import BannerRender from "@components/modules/Banner/BannerRender";
import ApplyJob from '@components/modules/Forms/ApplyJob';
import {
    imageBannerFragmentGql,
    NormalTextFragmentGql,
    IntroTextFragmentGql
} from "@components/gql";
import gql from "graphql-tag";
import { useQuery } from '@apollo/client';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import NotFoundPage from "../pages/404"
import Loader from '@components/loader';
const JobDetailsTemplate = (props) => {
   // const { glstrapi: { job } } = data
    const GET_DETAIL_JOB = gql`
    query GetJobDetail($Slug: String!) {
        jobs(where:{Slug: $Slug}){
            Pagename
            Title
            Slug
            HeaderTemplate
            BannerImage {
                ... on ComponentModulesBannerImage {
                    ...ImageBannerFragmentGql
                }
                ... on ComponentModulesNormalText {
                    ...NormalTextFragmentGql
                }
                ... on ComponentModulesIntroText {
                    ...IntroTextFragmentGql
                }
            }
        }
    }
    ${imageBannerFragmentGql}
    ${NormalTextFragmentGql}
    ${IntroTextFragmentGql}
`;
    const { loading, error, data } = useQuery(GET_DETAIL_JOB, {
        variables: { Slug: props.myslug }
    });
    useEffect(() => {
        if(typeof window !== 'undefined'){
            window.scrollTo(0,0)
            // window.addEventListener('contextmenu', function (e) {
            //     e.preventDefault();
            //   });
        }
    }, [data])
    let shareurl = ""
    if (typeof window !== "undefined") {
        shareurl = window.location.href ? window.location.href : ""
    }
    if (loading) return (
        <Layout loading={loading}>
        <Loader loading={loading} error={error} hideText={true} fullScreen />
        </Layout>
    );
    if (data?.jobs?.length === 0) return (
        <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
    )
    return (
        <Layout popularSearch={"About_Layout"} bodyClass="noBannerImage list-property-page job-details-page" headerClass="header-dark">
            {data?.jobs?.map((Page, i) => {
                const breadcrumbs = [
                    { label: "About", url: `${Routes.AboutPage}` },
                    { label: "Recruitment", url: `${Routes.AboutPage}${Routes.JobsPage}` },
                    { label: `${Page?.Pagename}`, url: `${window.location.pathname}` }
                ]
                return (
                    <>
                        <Seo
                            title={Page.Title}
                        // description={job?.Metadata?.description ? job.Metadata.description : null}
                        // image={job?.Metadata?.image?.url_sharp?.childImageSharp ? job.Metadata.image.url_sharp.childImageSharp.resize : null}
                        />
                        <BannerRender
                            page={Page}
                            breadcrumbs={breadcrumbs}
                            className="no-border-title"
                        />
                        {Page?.BannerImage &&
                            <ArticleText
                                moduleData={Page?.BannerImage}
                                sidebarData={[]}
                            />
                        }
                        <ApplyJob
                            sectionClass="section-grey section-contact-form"
                            title="Apply for this Job"
                            text="<p>Thank you for your interest. Please complete the details below and if you are short listed for an interview the Team will be in contact with you</p>"
                            shareurl={shareurl}
                            Job_Title={Page?.Title}

                        />
                    </>
                )
            })}
        </Layout>
    );
}

export default JobDetailsTemplate

// export const query = graphql`
// query jobDetailsQuery($id: ID!) {
//     glstrapi {
//         job(id: $id, publicationState: LIVE) {
//             Pagename
//             Title
//             Slug
//             HeaderTemplate
//             BannerImage {
//                 ... on GLSTRAPI_ComponentModulesBannerImage {
//                     ...ImageBannerFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesNormalText {
//                     ...NormalTextFragment
//                 }
//                 ... on GLSTRAPI_ComponentModulesIntroText {
//                     ...IntroTextFragment
//                 }
//             }
//         }
//     }
// }
// `