import React from "react";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import ApplyJobForm from '@components/forms/applyJob';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TeamContact from '@components/modules/TeamContact';

import { fadeIn } from '@components/utils/animations';

const ApplyJobModule = ({ sectionClass, title, text, shareurl, Job_Title }) => {
    return(
        <ModuleSection sectionClass={sectionClass + ' target-element'} id="applyForJob">
            <Row>
                <Col xs={12} lg={8} variants={fadeIn}>
                    {title &&
                        <ModuleTitle
                            as="h3"
                            title={title}
                            className="module__title mb-16 mb-md-24"
                        />
                    }
                    <ModuleText
                        text={text}
                        className="module__text mb-32 mb-md-56"
                    />
                    <ApplyJobForm shareurl={shareurl} Job_Title={Job_Title}  />
                </Col>
                <Col xs={12} lg={{ span: 3, offset: 1 }} variants={fadeIn} className="mt-40 mt-md-56 mt-lg-70">
                    <TeamContact moduleData={{ removeContainer: true, sectionSpacing: ' ' }} />
                </Col>
            </Row>
        </ModuleSection>
    )
}

export default ApplyJobModule